import React, { useState, Fragment } from 'react'
import Timeline from 'react-calendar-timeline'
// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'
import { Menu, Dropdown, Icon, message, AutoComplete } from 'antd';


const groups = [{ id: 1, title: 'group 1' }, { id: 2, title: 'group 2' }]

const items = [
  {
    id: 1,
    group: 1,
    title: 'item 1',
    start_time: moment(),
    end_time: moment().add(1, 'hour')
  },
  {
    id: 2,
    group: 2,
    title: 'item 2',
    start_time: moment().add(-0.5, 'hour'),
    end_time: moment().add(0.5, 'hour')
  },
  {
    id: 3,
    group: 1,
    title: 'item 3',
    start_time: moment().add(2, 'hour'),
    end_time: moment().add(3, 'hour')
  }
]


const IndiDashboard = (props) => {

  const [currentDev, setCurrentDev] = useState(null);
  const issues = props.items
  const devsList = issues.map(issue => issue.dev)
  console.log("IndiDashboard")
  console.log(issues)

  const onClick = ({ key }) => {
    message.info(`Click on item ${key}`);
    setCurrentDev(key)
  };

  const menu = (issues) => (
    <Menu onClick={onClick}>

      {issues.map(issue => <Menu.Item key={`${issue.dev}`}>{`${issue.dev}`}</Menu.Item>)}

      <Menu.Item key="1">1st menu item</Menu.Item>
      <Menu.Item key="2">2nd memu item</Menu.Item>
      <Menu.Item key="3">3rd menu item</Menu.Item>
    </Menu>
  );

  return (
    <>
      <AutoComplete
        style={{ width: 200 }}
        dataSource={devsList}
        placeholder="Select Assignee"
        filterOption={(inputValue, option) =>
          option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        onChange={(value) => setCurrentDev(value)}
      />
      <div>
        <p>Assignee: {currentDev}</p> <p>Open Issues: </p>
              </div>
      {/* <Dropdown overlay={menu(issues)}>
        <a className="ant-dropdown-link" href="#">
          Select Assignee <Icon type="down" />
        </a>
      </Dropdown> */}
      {/* <Timeline
      groups={groups}
      items={items}
      defaultTimeStart={moment().add(-12, 'hour')}
      defaultTimeEnd={moment().add(12, 'hour')}
    /> */}
    </>
  )

}

export default IndiDashboard