import React, { Component } from 'react'
import { Table } from 'antd';

// const columns = [
//   {
//     title: 'Issue#',
//     dataIndex: 'Key ID',
//     key: 'keyId',
//   },
//   {
//     title: 'Type',
//     dataIndex: 'Issue Type',
//     key: 'issueType',
//   },
//   {
//     title: 'Assignee',
//     dataIndex: 'Assignee',
//     key: 'assignee',
//   },
// ];


const AntTable = ({ items, loading, availHours }) => {

  const columns = [
    {
      title: 'Name',
      dataIndex: 'dev',
      key: 'dev',
      defaultSortOrder: 'dev',
      sorter: (a, b) => a.dev.localeCompare(b.dev),
      width: 400,
    },
    {
      title: 'Open',
      dataIndex: 'openIssues',
      key: 'openIssues',
      sorter: (a, b) => a.openIssues - b.openIssues,
      defaultSortOrder: 'descend',
      width: 120
    },
    {
      title: 'In-Progress',
      dataIndex: 'inProgress',
      key: 'inProgress',
      sorter: (a, b) => a.inProgress - b.inProgress,
      defaultSortOrder: 'descend',
      width: 120
    },
    {
      title: 'Total',
      dataIndex: 'totalIssues',
      key: 'totalIssues',
      sorter: (a, b) => a.totalIssues - b.totalIssues,
      defaultSortOrder: 'descend',
      width: 120
    },
    {
      title: 'Expired',
      dataIndex: 'expiredIssues',
      key: 'expiredIssues',
      sorter: (a, b) => a.expiredIssues - b.expiredIssues,
      defaultSortOrder: 'descend',
      width: 120
    },

        {
      title: 'Due Today',
      dataIndex: 'dueToday',
      key: 'dueToday',
      sorter: (a, b) => a.dueToday - b.dueToday,
      defaultSortOrder: 'descend',
      width: 120
    },
    {
      title: 'No Due Date',
      dataIndex: 'noDueDate',
      key: 'noDueDate',
      sorter: (a, b) => a.noDueDate - b.noDueDate,
      width: 120
    },
    {
      title: 'No Estimate',
      dataIndex: 'noEstimates',
      key: 'noEstimates',
      sorter: (a, b) => a.noEstimates - b.noEstimates,
      width: 120
    },
    {
      title: 'Total Est Hours',
      dataIndex: 'estimatedHours',
      key: 'estimatedHours',
      sorter: (a, b) => a.estimatedHours - b.estimatedHours,
      width: 120
    },
    {
      title: 'Delta Hours',
      dataIndex: 'estimatedHours',
      key: 'deltaHours',
      render: (text, row, index) => {
        return (
          <p>{availHours - parseInt(text)}</p>
        )
      },
      sorter: (a, b) => a.estimatedHours - b.estimatedHours,
      width: 120,
    },
  ];


  return (
    <Table dataSource={items} columns={columns} size="middle" pagination={false} loading={loading} scroll={{ y: window.innerHeight - 120 }} />
  )

}

export default AntTable