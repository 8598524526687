import React from 'react'
import moment from "moment"
import utils from './utils'
import AntTable from './AntTable'
import IndiDashboard from './IndiDashboard'
import './App.css';

const calcAntTableApi = (devs, devIssuesRaw) => {

  console.log("=== START calcAntTableApi ===")
  console.log(devs)
  console.log(devIssuesRaw)
  console.log("=== END calcAntTableApi ===")

  let returnArr = []

  devs.forEach((dev, devIdx) => {
    let expiredIssues = 0
    let noDueDate = 0
    let noEstimates = 0
    let estimatedHours = 0.0;
    let dueToday = 0;
    let openIssues = 0;
    let inProgress = 0;
    devIssuesRaw[devIdx].forEach(issue => {

      if (issue["dueDate"] === null) {
        noDueDate++
      } else if (moment(issue["dueDate"]).isBefore(new moment().subtract(1, 'days'))) {
        expiredIssues++
      } else if (moment(issue["dueDate"]).isSame(new moment())) {
        dueToday++
      }
      if (issue["estimatedHours"] == null) {
        noEstimates++
      } else {
        estimatedHours = estimatedHours + parseFloat(issue["estimatedHours"])
      }

      if (issue["status"]["id"] === 1) {
        openIssues++
      } else if (issue["status"]["id"] === 2) {
        inProgress++
      }

    })

    returnArr.push({
      dev,
      totalIssues: devIssuesRaw[devIdx].length,
      expiredIssues,
      noDueDate,
      noEstimates,
      estimatedHours: Math.ceil(estimatedHours),
      dueToday,
      openIssues,
      inProgress
    })
  })

  return returnArr
}

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      expiredIssues: 0,
      groupByAssignee: {},
      devs: [],
      devIssuesRaw: [],
      antTable: [],
      offset: 0,
      loading: true,
      availDays: 50,
    }
    this.updateAvailDays = this.updateAvailDays.bind(this);
  }

  async componentDidMount() {
    let items = await this.fetchData()

    items.forEach(item => {
      item['dev'] = item.assignee != null ? item.assignee.name : ''
    })

    let devs = [], devIssuesRaw = [];
    const groupByAssigneeInter = utils.groupBy(['dev']);
    const groupByAssignee = groupByAssigneeInter(items)

    Object.entries(groupByAssignee).forEach(([dev, issues]) => {
      devs.push(dev)
      devIssuesRaw.push(issues)
    })

    let antTable = calcAntTableApi(devs, devIssuesRaw)

    this.setState({
      items,
      groupByAssignee,
      devs,
      devIssuesRaw,
      antTable,
      loading: false
    });
  }

  updateAvailDays = (e) => {
    console.log(e.target.value)
    this.setState({
      availDays: e.target.value
    })
  }

  fetchData = async () => {
    let offset = 0
    let returnItems = []
    let json = []
    let counter = 0
    do {
      console.log("while ST: ", offset)
      const response = await fetch(`https://clinivan.backlog.com/api/v2/issues?apiKey=EhyzzNSe00j69JY6IAJfQL2g0Q62IXZQGWT5pxr8Rs1jqBr7sjY438UYwSHv0rgQ&projectId[]=24797&statusId[]=1&statusId[]=2&count=100&offset=${offset}`)

      json = await response.json();
      console.dir(json)

      returnItems.push(...json)
      offset = offset + json.length

      console.log("while EN: ", offset)
      counter++

    }
    // while (counter < 2)
    while (json.length === 100)

    console.log("=== START fetchData ===")
    console.dir(returnItems)
    console.log("=== END fetchData ===")

    return returnItems
  }

  render() {
    const { items, antTable, loading, availDays } = this.state

    if (loading) {
      return (
        <AntTable items={antTable} loading={true} />
      )
    }
    return (
      <div>
        {/* <input type="file" name="file" onChange={this.onChangeHandler} /> */}
        <p>Working Days: <input type="number" name="availDays" value={availDays} onChange={this.updateAvailDays} /></p>

        {items && items.length > 0 && <AntTable items={antTable} loading={false} availHours={availDays * 10} />}
        {/* {items && items.length > 0 && <IndiDashboard items={antTable} />} */}
      </div>
    )
  }

}

export default App